import { ChangeEvent, FocusEvent, FC, forwardRef, useState, useCallback } from 'react';
import { useFormAction } from '../../../../contexts/FormActionContext';
import ActionBaseProps from '../ActionBaseProps';
import ActionTitleDescription from '../ActionTitleDescription';
import PhoneInput, { Country, Value } from 'react-phone-number-input';
import CountryCodeSelect from '../../../shared/CountryCodeSelect';
import { Input } from '../../../shared/form-control/Input';
import { isPossiblePhoneNumber, getCountryCallingCode } from 'libphonenumber-js';
import { useTranslation } from 'react-i18next';
import XIcon from '../../../shared/icon/XIcon';
import StringUtils from '../../../../utils/StringUtils';

type PhoneNumberActionData = {
  countryCode: Country;
};

export type PhoneNumberResponse = {
  countryCode: Country;
  phoneNumber: Value;
};

type PhoneNumberActionProps = ActionBaseProps<PhoneNumberResponse, PhoneNumberActionData>;

type PhoneNumberInputProps = {
  value: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  isValid?: boolean;
  hasFocus?: boolean;
  clearInput?: () => void;
  disabled?: boolean;
  autoFocus?: boolean;
  error?: string;
  required?: boolean;
};

export const PhoneNumberInput: FC<PhoneNumberInputProps> = forwardRef<HTMLInputElement, PhoneNumberInputProps>(function PhoneNumberInput(props, ref) {
  const { value, onChange, onFocus, onBlur, isValid, hasFocus, clearInput, disabled, autoFocus, error, required } = props;
  return (
    <Input
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      innerRef={ref}
      errorState={!isValid}
      className="rounded-l-none"
      wrapperClassName="rounded-l-none"
      disabled={disabled}
      autoFocus={autoFocus}
      error={error}
      required={required}
    >
      <Input.Slot name="trailing">{hasFocus && <XIcon className="mr-2 h-4 w-4 cursor-pointer" onClick={clearInput} />}</Input.Slot>
    </Input>
  );
});

const PhoneNumberAction: FC<PhoneNumberActionProps> = (props) => {
  const { response, data, required } = props;
  const { countryCode } = data;
  const { onAnswer, onValid, readOnly } = useFormAction(props);
  const [selectedCountryCode, setSelectedCountryCode] = useState(response?.countryCode || countryCode || 'DE');
  const [phoneNumber, setPhoneNumber] = useState(
    response?.phoneNumber && StringUtils.hasCountryCode(response?.phoneNumber)
      ? response.phoneNumber || ''
      : `+${getCountryCallingCode(selectedCountryCode)}${response?.phoneNumber || ''}`,
  );
  const [validationError, setValidationError] = useState('');
  const { t } = useTranslation('activity-type');
  const [hasFocus, setHasFocus] = useState(false);

  const onPhoneInputBlur = (e: FocusEvent<HTMLInputElement>) => {
    const isValid = isPossiblePhoneNumber(e.target.value);
    setValidationError(!isValid ? t('phone-number.invalid') : '');
    onValid(isValid);
    if (isValid) {
      onAnswer({ countryCode: selectedCountryCode, phoneNumber: e.target.value.replaceAll(' ', '') as Value });
    }
  };

  const onPhoneInputChange = (value: Value) => {
    setPhoneNumber(value);
  };

  const onCountryChange = (country: Country) => {
    setSelectedCountryCode(country);
    onAnswer(null);
    setValidationError('');
    onValid(false);
  };

  const clearInput = () => {
    setPhoneNumber('');
  };

  const SelectComponent = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (props: any) => (
      <div className="w-28">
        <CountryCodeSelect
          {...props}
          menuPosition="left"
          flagOnly={true}
          className={`rounded-r-none border-r-0 ${!!validationError && 'border-red-500'}`}
          disabled={readOnly}
        />
      </div>
    ),
    [readOnly, validationError],
  );

  return (
    <div data-cy="phone-number-action">
      <ActionTitleDescription required={required} {...data} />
      <PhoneInput
        international
        defaultCountry={selectedCountryCode}
        value={phoneNumber || ''}
        onChange={onPhoneInputChange}
        onCountryChange={onCountryChange}
        countryCallingCodeEditable={false}
        disabled={readOnly}
        countrySelectComponent={SelectComponent}
        inputComponent={PhoneNumberInput}
        numberInputProps={{ isValid: !validationError, hasFocus, clearInput: clearInput, disabled: readOnly }}
        onBlur={onPhoneInputBlur}
        onFocus={() => setHasFocus(true)}
        className="flex w-[300px] items-center"
      />
      <p className="text-dpm-14 py-1 text-red-500">{validationError}</p>
    </div>
  );
};

export default PhoneNumberAction;
